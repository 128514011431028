import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleInitializedUserNote, getTemplates } from '../../../controllers/NoteController';
import close from './assets/close cross.svg';
// import mic from './assets/Microphone.svg';

const PronounButton = ({ value, selectedPronoun, setPronoun }) => {
	const isSelected = value === selectedPronoun;
	return (
		<button
			className={`w-1/3 py-2 rounded-[25px] text-center font-Inter font-medium text-base ${
				isSelected ? 'bg-[#00808066] text-white' : 'text-[#282D2D]'
			}`}
			onClick={() => setPronoun(value)}
		>
			{value}
		</button>
	);
};

const UserFormModal = ({ isVisible, onClose, handleClick, onPatientCreated }) => {
	const [patient_pronoun, setPatientPronoun] = useState('He'); // Default pronoun selection
	const [patient_name, setPatientName] = useState('');
	const [patient_age, setPatientAge] = useState('');
	const [height, setHeight] = useState('');
	const [noteType, setNoteType] = useState('');
	const [noteTypes, setNoteTypes] = useState([]);
	// const [errors, setErrors] = useState({});
	const Navigate = useNavigate();

	useEffect(() => {
		const getNoteTemplates = async () => {
			try {
				const data = await getTemplates();
				setNoteTypes(data?.data?.data);

				const templateId = await localStorage.getItem('template_id');
				if (templateId) {
					setNoteType(templateId);
				} else {
					setNoteType(data?.data?.data[0]?.id);
				}
			} catch (error) {
				console.error('Error fetching note types:', error);
			}
		};

		getNoteTemplates();
	}, []);

	if (!isVisible) return null;

	const onSuccess = (response) => {
		const patientData = {
			name: response.data.patient_name,
			pronoun: response.data.patient_pronoun,
		};

		localStorage.setItem('note_id', response.data.id);
		localStorage.setItem('patient_info', JSON.stringify(patientData));

		if (onPatientCreated) onPatientCreated({ res: response.data.id }); // Emit the response to the parent

		setPatientPronoun('He');
		setPatientName('');
		setPatientAge('');
		setHeight('');
		setNoteType('');

		Navigate('/transcription');
		window.location.reload();
		// handleClick('Transcribe');
		// console.log('Transcribe');
	};

	const handleInitializedUserNoteClick = () => {
		let template_id = noteType.toString(); // Using noteType as template_id since it's set to template.id

		const userData = {
			patient_name,
			patient_age: patient_age ? Number(patient_age) : null,
			// height,
			patient_pronoun,
			template_id: Number(template_id),
		};

		// const newErrors = {};
		// let isEmpty = false;

		// if (!userData.patient_name) {
		// 	newErrors.patient_name = 'Patient name is required';
		// 	isEmpty = true;
		// }

		// if (isEmpty) {
		// 	if (userData.patient_name) {
		// 		userData.patient_name = userData.patient_name.replace(/_/g, ' ');
		// 	}
		// 	setErrors(newErrors);
		// 	return;
		// }

		// setErrors({});
		handleInitializedUserNote(userData, onSuccess);
	};

	return (
		<div className='w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center'>
			<div className='w-full flex justify-center items-center'>
				<div className='md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[550px] p-6 rounded-[15px] w-full md:w-[400px]'>
					<div className='w-full h-full bg-white'>
						<span className='flex items-center justify-between'>
							<p className='font-BricolageGrotesque font-semibold text-xl text-[#272D37]'>
								Patient Details{' '}
								<sup className='bg-[#272D37] text-white px-2 py-[2px] text-[8px] align-super tracking-widest uppercase rounded-full'>
									Optional
								</sup>
							</p>
							<img onClick={onClose} src={close} className='cursor-pointer' alt='Close' />
						</span>
						<div className='mt-6'>
							<label className='flex w-full flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm'>
								Name
								<input
									className='mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[20px] px-5 py-2.5'
									type='text'
									placeholder='Enter Patient Name'
									value={patient_name}
									onChange={(e) => setPatientName(e.target.value)}
								/>
								{/* {errors.patient_name && <p className='text-red-500 text-xs mt-1'>{errors.patient_name}</p>} */}
							</label>
							<div className='mt-6 flex items-center justify-between w-full'>
								<label className='w-[49%] flex flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm'>
									Age
									<input
										className='mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[20px]  px-5 py-2.5'
										type='number'
										min={0}
										max={140}
										placeholder='Enter Age'
										value={patient_age}
										onChange={(e) => {
											let value = e.target.value;
											if (/^\d*$/.test(value)) {
												// value = Math.max(0, Math.min(140, value)); // Ensure the value stays between 0 and 140
												setPatientAge(value);
											}
										}}
									/>
									{/* {errors.patient_age && <p className='text-red-500 text-xs mt-1'>{errors.patient_age}</p>} */}
								</label>
								<label className='hidden w-[49%] flex-col text-[#272D37] font-semibold font-BricolageGrotesque text-sm'>
									Height
									<input
										className='mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[20px] px-5 py-2.5'
										type='text'
										placeholder='Enter Height'
										value={height}
										onChange={(e) => setHeight(e.target.value)}
									/>
								</label>
							</div>
							<label className='mt-6 flex flex-col w-full text-[#272D37] font-semibold font-BricolageGrotesque text-sm'>
								Pronoun
								<span className='w-full flex items-center p-1 mt-[6px] border border-[#DAE0E6] rounded-[40px]'>
									{['He', 'She', 'They'].map((value) => (
										<PronounButton
											key={value}
											value={value}
											selectedPronoun={patient_pronoun}
											setPronoun={setPatientPronoun}
										/>
									))}
								</span>
							</label>
							<label className='mt-6 flex flex-col w-full text-[#272D37] font-semibold font-BricolageGrotesque text-sm'>
								Choose Note Type
								<span className='w-full flex items-center py-3 px-4 mt-[6px] border border-[#DAE0E6] rounded-[20px]'>
									<select
										className='bg-transparent font-Inter font-medium text-sm text-[#272D37] w-full focus:outline-none focus:ring-2 focus:ring-gray-300'
										value={noteType}
										onChange={(e) => setNoteType(e.target.value)}
									>
										{noteTypes.map((template) => (
											<option key={template.id} value={template.id}>
												{template.name}
											</option>
										))}
									</select>
								</span>
							</label>
							<button
								onClick={handleInitializedUserNoteClick}
								className='mt-6 mb-2 w-full space-x-3 flex items-center bg-[#00AAAA] rounded-[30px] justify-center py-3'
							>
								{/* <img src={mic} alt='Mic' /> */}
								<p className='text-white font-BricolageGrotesque font-semibold text-base'>Proceed</p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserFormModal;
